import semImg from '@/assets/images/sem_foto.png';

export default {

    // First a couple helper functions
    $(id) {
        return !id || id.nodeType === 1 ? id : document.getElementById(id);
    },

    isType(o,t) {    
        return (typeof o).indexOf(t.charAt(0).toLowerCase()) === 0;
    },

    // Here's the meat and potatoes
    image(src,cfg) {    
        var img, prop, target;
        cfg = cfg || (this.isType(src,'o') ? src : {});

        img = this.$(src);
        if (img) {
            src = cfg.src || img.src;
        } else {
            img = document.createElement('img');
            src = src || cfg.src;
        }

        if (!src) {
            //return null;
            src = semImg;
        }

        prop = this.isType(img.naturalWidth,'u') ? 'width' : 'naturalWidth';
        img.alt = cfg.alt || img.alt;

        // Add the image and insert if requested (must be on DOM to load or
        // pull from cache)
        img.src = src;

        target = this.$(cfg.target);
        if (target) {
            target.insertBefore(img, $(cfg.insertBefore) || null);
        }

        // Loaded?
        if (img.complete) {
            if (img[prop]) {
                if (this.isType(cfg.success,'f')) {
                    cfg.success.call(img);
                }
            } else {
                //img.src = semImg;
                //img.title = 'Sem Imagem / Imagem não Encontrada.'
                if (this.isType(cfg.failure,'f')) {
                    cfg.failure.call(img);
                }
            }
        } else {
            if (this.isType(cfg.success,'f')) {
                img.onload = cfg.success;
            }
            if (this.isType(cfg.failure,'f')) {
                //img.src = semImg;
                //img.title = 'Sem Imagem / Imagem não Encontrada.'            
                img.onerror = cfg.failure;
            }
        }
        return img.src;
    },    
}